import { ProfilePage } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/Profile/ProfilePage';
import { UserPlatformManagementEntityTypes } from '@cfra-nextgen-frontend/shared/src/utils';

export function Package() {
    return (
        <ProfilePage
            entityType={UserPlatformManagementEntityTypes.Package}
            getProfileTitle={getPackageTitle}
            editProfileButtonText='Edit Package'
        />
    );
}

function getPackageTitle(data: any) {
    return 'Package';
}
