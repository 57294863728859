import { AnalyticsTickerSearchContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/analytics/analyticsTickerSearchContext/Context';
import {
    CreateModal,
    CreateModalProps,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/components/CreateModal';
import { FiltersModalContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { MultipleModalsContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/MultipleModalsContext/MultipleModalsContext';
import { ResultsContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { CreateFormProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/forms/CreateForm';
import { invalidateQueriesByKey } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { SnackbarProvider } from 'notistack';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ScreenerCard, ScreenerCardProps } from './ScreenerCard';

export type ScreenerHomeProps = ScreenerCardProps & {};

export function ScreenerHome({
    cardName,
    searchPlaceholder,
    enableTopLeftActionPanel,
    enableTopRightActionPanel,
    useSSRMode,
    screenerRequestParams,
    analyticsCreateModalFormName,
    rowLevelFiltersConfig,
    maxGridContainerHeightPercentage,
    unlimitedCalculatedHeight,
    modals,
}: ScreenerHomeProps) {
    const navigate = useNavigate();
    const { id } = useParams();
    const [entityId] = useState<number | undefined>(id ? parseInt(id) : undefined);

    const screenerCard = useMemo(() => {
        return (
            <ScreenerCard
                cardName={cardName}
                modals={modals}
                searchPlaceholder={searchPlaceholder}
                enableTopLeftActionPanel={enableTopLeftActionPanel}
                enableTopRightActionPanel={enableTopRightActionPanel}
                useSSRMode={useSSRMode}
                screenerRequestParams={screenerRequestParams}
                rowLevelFiltersConfig={rowLevelFiltersConfig}
                analyticsCreateModalFormName={analyticsCreateModalFormName}
                maxGridContainerHeightPercentage={maxGridContainerHeightPercentage}
                unlimitedCalculatedHeight={unlimitedCalculatedHeight}
            />
        );
    }, [
        cardName,
        modals,
        searchPlaceholder,
        enableTopLeftActionPanel,
        enableTopRightActionPanel,
        useSSRMode,
        screenerRequestParams,
        analyticsCreateModalFormName,
        rowLevelFiltersConfig,
        maxGridContainerHeightPercentage,
        unlimitedCalculatedHeight,
    ]);

    const multipleModalsJsx = useMemo(() => {
        if (!modals) {
            return null;
        }

        return modals.map(({ props, ModalWrapper }) => {
            const onCreationSuccess: CreateFormProps['onCreationSuccessCallback'] = (createdItemId?: number) => {
                invalidateQueriesByKey('getFiltersData');
                invalidateQueriesByKey('getScreenerData');

                if (!createdItemId) {
                    return;
                }

                setTimeout(() => navigate(`/${props.entityType}/${createdItemId}`), 300);
            };

            const modalProps: { key: string } & CreateModalProps = {
                key: props.title,
                modalTitle: props.title,
                entityType: props.entityType,
                entityId,
                onOperationSuccessSnackMessage: props.onOperationSuccessSnackMessage,
                submitButtonName: props.submitButtonName,
                onBeforeCreateSuccess: props.onBeforeCreateSuccess,
                onCreationSuccessCallback: onCreationSuccess,
                operationType: props.operationType,
                requestPath: props.requestPath,
                description: props.description,
                multipleResultsPopup: props.multipleResultsPopup,
                multipleResultsPopupId: props.multipleResultsPopupId,
                multipleResultsPopupTitle: props.multipleResultsPopupTitle,
                maxNumberOfItemsPerOneRequest: props.maxNumberOfItemsPerOneRequest,
                presetValues: props.presetValues,
                localFiltersMetadata: props.localFiltersMetadata,
            };

            if (ModalWrapper) {
                return <ModalWrapper {...modalProps} ModalComponent={CreateModal} />;
            }

            return <CreateModal {...modalProps} />;
        });
    }, [navigate, modals, entityId]);

    return (
        <ResultsContextProvider>
            <MultipleModalsContextProvider>
                <FiltersModalContextProvider>
                    <AnalyticsTickerSearchContextProvider>{screenerCard}</AnalyticsTickerSearchContextProvider>
                </FiltersModalContextProvider>
                {modals && (
                    <SnackbarProvider
                        maxSnack={3}
                        classes={{
                            containerRoot: 'cfra-snackbar-root',
                        }}>
                        {multipleModalsJsx}
                    </SnackbarProvider>
                )}
            </MultipleModalsContextProvider>
        </ResultsContextProvider>
    );
}
